import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "align-items-end flex-column" }
const _hoisted_7 = { class: "btn btn-primary btn-light" }
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-auto" }
const _hoisted_12 = { class: "fs-5 text-dark text-hover-primary fw-bolder pt-4" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "col-auto" }
const _hoisted_16 = { class: "fs-5 text-dark text-hover-primary fw-bolder pt-4" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "table-responsive" }
const _hoisted_21 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_22 = { class: "fw-bolder text-muted" }
const _hoisted_23 = { class: "min-w-150px" }
const _hoisted_24 = { class: "min-w-120px" }
const _hoisted_25 = { class: "min-w-120px" }
const _hoisted_26 = { class: "min-w-120px" }
const _hoisted_27 = { class: "min-w-120px" }
const _hoisted_28 = { class: "min-w-100px text-end" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_31 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_32 = { class: "badge-light badge text-primary" }
const _hoisted_33 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("pages.surveys.title")), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("pages.surveys.desc")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, { to: "/surveys/add/" }, {
          default: _withCtx(() => [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("pages.surveys.addSurvey")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.t("pages.surveys.date_begin")), 1)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.date_begin) = $event)),
              type: "date",
              name: "fname",
              class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
              placeholder: _ctx.$t('pages.surveys.date_begin')
            }, null, 8, _hoisted_14), [
              [_vModelText, _ctx.filter.date_begin]
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.t("pages.surveys.date_end")), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.date_end) = $event)),
              type: "date",
              name: "fname",
              class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
              placeholder: _ctx.$t('pages.surveys.date_end')
            }, null, 8, _hoisted_18), [
              [_vModelText, _ctx.filter.date_end]
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary btn-light",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchUsers()))
            }, _toDisplayString(_ctx.$t("general.search")), 1),
            _createElementVNode("button", {
              type: "submit",
              class: "ms-3 btn btn-icon btn-light btn-active-light-primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetFilter()))
            }, _cache[4] || (_cache[4] = [
              _createElementVNode("i", { class: "fas fa-redo fs-7 pt-1" }, null, -1)
            ]))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("table", _hoisted_21, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", _hoisted_22, [
              _createElementVNode("th", _hoisted_23, _toDisplayString(_ctx.$t("pages.surveys.label")), 1),
              _createElementVNode("th", _hoisted_24, _toDisplayString(_ctx.$t("pages.surveys.date")), 1),
              _createElementVNode("th", _hoisted_25, _toDisplayString(_ctx.$t("pages.surveys.langue")), 1),
              _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t("pages.surveys.population")), 1),
              _createElementVNode("th", _hoisted_27, _toDisplayString(_ctx.$t("pages.surveys.documents")), 1),
              _createElementVNode("th", _hoisted_28, _toDisplayString(_ctx.$t("pages.surveys.actions")), 1)
            ])
          ]),
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_29, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.surveys, (survey) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: survey.id
                  }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("a", _hoisted_30, _toDisplayString(survey.label), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("a", _hoisted_31, _toDisplayString(survey.date_begin) + " - " + _toDisplayString(survey.date_end), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("span", _hoisted_32, _toDisplayString(survey.langues), 1)
                    ]),
                    _cache[5] || (_cache[5] = _createElementVNode("td", null, [
                      _createElementVNode("button", { class: "btn btn-primary btn-sm me-2" }, "Download"),
                      _createElementVNode("button", { class: "btn btn-primary btn-sm" }, "Upload")
                    ], -1)),
                    _cache[6] || (_cache[6] = _createElementVNode("td", null, [
                      _createElementVNode("button", { class: "btn btn-primary btn-sm" }, "Download questions")
                    ], -1)),
                    _cache[7] || (_cache[7] = _createElementVNode("td", { class: "text-end" }, [
                      _createElementVNode("a", {
                        href: "#",
                        class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      }, [
                        _createElementVNode("span", { class: "svg-icon svg-icon-3" }, [
                          _createElementVNode("svg", {
                            fill: "none",
                            viewBox: "0 0 24 24",
                            height: "24",
                            width: "24",
                            xmlns: "http://www.w3.org/2000/svg"
                          }, [
                            _createElementVNode("path", {
                              xmlns: "http://www.w3.org/2000/svg",
                              opacity: "0.3",
                              d: "M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z",
                              fill: "black"
                            }),
                            _createElementVNode("path", {
                              xmlns: "http://www.w3.org/2000/svg",
                              d: "M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z",
                              fill: "black"
                            })
                          ])
                        ])
                      ])
                    ], -1))
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, _cache[8] || (_cache[8] = [
              _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-200px" }, [
                _createElementVNode("span", {
                  class: "spinner-border text-primary",
                  role: "status"
                })
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}